<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("branch_picker_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('branch_picker_page.form_desc')"
    ></div>
    <!-- <div  class="page__content" v-if="getModuleConfig('branch_picker_page.content')" v-html="getModuleConfig('branch_picker_page.content')"></div> -->

    <coupon-filters
      class="s-mb-3"
      :options="filterOptions"
      v-model="filter"
    ></coupon-filters>

    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <div v-else-if="!showLoading && filter == 'qrcode'">
        <QRCodeScanner
          @onScan="onScan"
        />
        <input
            class="search__input mx-auto w-100 mt-3" disabled :value="barcode"
        />
      </div>
      <div v-else-if="!showLoading && filter == 'manual'">
        <input
          class="search__input m-auto w-100"
        />
      </div>
    </div>

    <div
      v-if="
        getModuleConfig('branch_picker_page.button_text') ||
        getModuleConfig('branch_picker_page.cancel_button_text')
      "
      class="p-3 fixed-bottom bg-white footer"
    >
      <SharedButton class="s-btn-bg-primary mb-2">{{
        getModuleConfig("branch_picker_page.button_text")
      }}</SharedButton>
      <SharedButton variant="outline-dark" class="s-btn-outline-primary">{{
        getModuleConfig("branch_picker_page.cancel_button_text")
      }}</SharedButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import branchMixin from "@/mixins/liff/branch";
import CouponFilters from "@/pages/Dashboard/Coupon/CouponFilters.vue";
import QRCodeScanner from "@/components/QRCodeScanner";

export default {
  mixins: [branchMixin],
  components: { Loading, SharedButton, CouponFilters, QRCodeScanner },
  data() {
    return {
      showLoading: false,
      options: [
        { text: "Avnet", value: "Avnet" },
        { text: "Arrow", value: "Arrow" },
        { text: "Burnon", value: "Burnon" },
      ],
      proxy: null,
      filter: "qrcode",
      barcode: null,
    };
  },

  computed: {
    filterOptions() {
      return [
        {
          key: "qrcode",
          text: "QR Code 掃描",
        },
        {
          key: "manual",
          text: "手動輸入",
        },
      ];
    },
  },
  mounted() {},

  methods: {
    onScan (decodedText) {
      this.barcode = decodedText
    }
  },
};
</script>

<style lang="scss" scoped>
.search {


  &__input {
    background: #ffffff;
    border-radius: 10px !important;
    border: 1px solid var(--liff-button-color);
    padding: 15px 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }

    &:focus {
      border: 1px solid var(--liff-button-color);
      outline: none;
    }
  }
}
</style>
